
import { Component, Vue } from 'vue-property-decorator';
import MyCard from "./MyCard.vue";
import ApplyDialog from "@/components/RetainInfoDialog/ApplyDialog.vue";
import PerfectDialog from "@/components/RetainInfoDialog/PerfectDialog.vue";
import OpinionDialog from "@/components/RetainInfoDialog/OpinionDialog.vue";

@Component({
    name: "ExperienceCard",
    components: {
        MyCard,
        ApplyDialog,
        PerfectDialog,
        OpinionDialog,
    },
})
export default class ExperienceCard extends Vue {
    private baseUrlIframe = process.env.VUE_APP_URL;
    private productList: any = [];
    private proList: any = [];
    private applyVisible: boolean = false;
    private perfectVisible: boolean = false;
    private opinionVisible: boolean = false;
    private created() {
        this.initData();
    }
    // 初始化加载数据
    private initData() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/individual/systems/experiences_trials')
        .then((res: any) => {
            this.productList = res;
            this.proList = res.map((item: any) => ({
                id: item.mcId,
                name: item.name,
            }));
        });
    }
    // 建议反馈
    private openOpinion() {
        this.opinionVisible = true;
    }
    private closeOpinion(num: number) {
        this.opinionVisible = false;
    }
    // 立即体验
    private experienceNow(row: any) {
        this.perfectVisible = true;
    }
    private closeExperienceNow(num: number) {
        this.perfectVisible = false;
    }
    // 申请试用
    private applyTrial(row: any) {
        // 判断是否重复提交
        this.$message({
            message: '重复提交，您提交的试用申请正在处理中，请勿重复提交',
            type: 'warning',
        });
        this.applyVisible = true;
    }
    private closeApplyTrial(num: number) {
        this.applyVisible = false;
    }
    // 更多体验产品
    private moreExperience(url: string) {
        window.open(`${this.baseUrlIframe}/v1/main/learn/index.htm`);
    }
    // 继续使用
    private async openUrl(url: string, isSelfBuiltRoom: number) {
        if (await this.$isLogin()) {
            if (isSelfBuiltRoom === 1) {
                const msg = `限摩尔元数VPN环境下使用，如无VPN账号请在钉钉OA审批中发起申请（非公司员工请联系生态或项目经理申请）<a style="font-size:12px; color:#999;" href="https://secloud1.ruijie.com.cn/SSLVPNClient">VPN客户端下载地址`;
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    dangerouslyUseHTMLString: true,
                    type: 'warning',
                }).then(() => {
                    // 确定
                    window.open(url);
                }).catch(() => {
                    // 取消
                });
            } else {
                window.open(url);
            }
        } else {
            this.$alert('登录状态已过期，请重新登录', '提示', {
                showClose: false,
                confirmButtonText: '重新登录',
                callback: (action: any) => {
                    // 清除各种缓存数据
                    localStorage.clear();
                    // 跳转到登录页面
                    this.$router.push("/login").catch((err) => err);
                },
            });
        }
    }
    // 立即购买
    private buyNow(row: any) {
        // 个人用户：提示切换企业用户
        this.$confirm('抱歉，暂不支持个人用户购买产品，如需购买请先创建企业并完成企业实名认证。', '提示', {
            confirmButtonText: '我知道了',
            showCancelButton: false,
        });
        // 企业用户：跳转商品详情
    }
}
